<template>
  <div id="testingSyllabusEdit">
    <Drawer
      :mask-closable="false"
      :transfer="false"
      @on-close="close"
      class="bk-sub-drawer testing-syllabus-edit"
      v-model="flag"
      width="600"
    >
      <Spin class="bk-loading" fix v-if="loading">
        <div>
          <Icon type="ios-loading"></Icon>
          <div>Loading</div>
        </div>
      </Spin>
      <Spin class="bk-failed" fix v-if="failed">
        <div @click="req">
          <Icon type="md-refresh"></Icon>
          <p>Refresh</p>
        </div>
      </Spin>
      <div class="ivu-drawer-header-inner" slot="header">
        <span>测试大纲</span>
        <Divider type="vertical" />
        <span
          class="text-info"
          v-if="id > 0"
        >{{ $designConst.testingSyllabus.Status[formd.Status] }}</span>
      </div>
      <div class="bk-drawer-body">
        <Form
          :label-width="110"
          :model="formd"
          :rules="rules"
          @submit.native.prevent
          inline
          ref="form"
        >
          <FormItem label="委托单：" prop="OrderId" required>
            <Button
              @click="fastPickOrder"
              ghost
              size="small"
              style="margin-bottom: 8px"
              type="primary"
              v-if="ABLE && $store.state.order.Id"
            >{{ $store.state.order.Code }}</Button>
            <Input :value="formd.OrderCode" @on-clear="unPickOrder" clearable readonly v-if="ABLE">
              <Button @click="toPickOrder" icon="ios-more" slot="append"></Button>
            </Input>
            <div v-else>{{formd.OrderCode}}</div>
          </FormItem>
          <FormItem label="测试项目：" prop="ItemId" required>
            <Select :disabled="!formd.OrderId" v-if="ABLE" v-model="formd.ItemId">
              <Option :key="item.Id" :value="item.Id" v-for="item in orderItems">{{item.Name}}</Option>
            </Select>
            <div v-else>{{formd.ItemName}}</div>
          </FormItem>
          <!-- <FormItem label="大纲名称：" prop="Name" required>
            <Input v-if="ABLE" v-model="formd.Name" />
            <div v-else>{{formd.Name}}</div>
          </FormItem>-->
          <FormItem label="大纲编码：" prop="Code" required>
            <Input v-if="ABLE" v-model="formd.Code" />
            <div v-else>{{formd.Code}}</div>
          </FormItem>
          <FormItem label="编制人：" prop="EditorId" required>
            <Input
              :value="formd.EditorName"
              @on-clear="unPickEditor"
              clearable
              readonly
              v-if="ABLE"
            >
              <Button @click="toPickEditor" icon="ios-more" slot="append"></Button>
            </Input>
            <div v-else>{{formd.EditorName}}</div>
          </FormItem>
          <FormItem label="开始测试时间：" v-if="formd.BeginTime">{{formd.BeginTime}}</FormItem>
          <FormItem label="结束测试时间：" v-if="formd.EndTime">{{formd.EndTime}}</FormItem>
          <FormItem class="long" label="描述：">
            <Input type="textarea" v-if="ABLE" v-model="formd.Description" />
            <div v-else>{{formd.Description}}</div>
          </FormItem>
          <FormItem class="long" label="备注：">
            <Input type="textarea" v-if="ABLE" v-model="formd.Remark" />
            <div v-else>{{formd.Remark}}</div>
          </FormItem>
        </Form>
      </div>
      <div class="bk-drawer-footer" v-if="ABLE">
        <Button :loading="posting" @click="ok(0)" ghost type="primary" v-if="formd.Status==0">保存为草稿</Button>
        <Button :loading="posting" @click="ok(1)" type="primary" v-if="formd.Status==0">保存并提交</Button>
        <Button :loading="posting" @click="ok(1)" type="primary" v-if="formd.Status>=1">保存</Button>
      </div>
    </Drawer>
    <pick-order @on-ok="onPickOrder" ref="PickOrder" />
    <pick-user :type="1" @on-ok="onPickEditor" ref="PickUser" title="选择编制人" />
  </div>
</template>
<script>
import PickOrder from "../../components/PickOrder.vue";
import PickUser from "../../components/PickUser.vue";
export default {
  components: { PickOrder, PickUser },
  data() {
    return {
      ABLE: false,
      id: 0,
      flag: false,
      loading: false,
      failed: false,
      posting: false,
      formd: {},
      rules: {
        OrderId: [{ required: true, type: "number", message: " " }],
        ItemId: [{ required: true, type: "number", message: " " }],
        Name: [{ required: true, message: " " }],
        Code: [{ required: true, message: " " }],
        EditorId: [{ required: true, type: "number", message: " " }],
      },
      orderItems: [],
      status: 0,
    };
  },
  methods: {
    open(id) {
      if (id) {
        this.ABLE = this.$able.map.BtmsSyllabusUpd;
        this.id = id;
        this.req();
      } else {
        this.ABLE = this.$able.map.BtmsSyllabusAdd;
        this.formd.Status = 0;
      }
      this.flag = true;
    },
    close() {
      this.ABLE = false;
      this.id = 0;
      this.flag = false;
      this.loading = false;
      this.failed = false;
      this.posting = false;
      this.$refs.form.resetFields();
      this.formd = {};
    },
    ok(status) {
      this.status = status;
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.postData();
        }
      });
    },
    //
    reqItems() {
      this.$axios
        .get("/Api/BtmsOrderItems/QueryByOrder", {
          params: {
            OrderId: this.formd.OrderId,
          },
        })
        .then((res) => {
          if (res.result) {
            this.orderItems = res.data.filter((v) => v.ParentId == 0);
          }
        });
    },
    req() {
      this.loading = true;
      this.failed = false;
      this.$axios
        .get("/Api/BtmsTestingSyllabus/GetVo", { params: { Id: this.id } })
        .then((res) => {
          this.loading = false;
          if (res.result) {
            this.formd = res.data;
            this.reqItems();
          } else {
            this.failed = true;
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.failed = true;
          this.$Message.error(err.errormsg);
        });
    },
    postData() {
      this.posting = true;
      let api =
        this.id > 0
          ? "/Api/BtmsTestingSyllabus/Update"
          : "/Api/BtmsTestingSyllabus/Add";
      let param = this.$util.copy(this.formd);
      param.Status = this.status;
      this.$axios
        .post(api, param)
        .then((res) => {
          this.posting = false;
          if (res.result) {
            if (this.id > 0) {
              this.$Message.success("编辑成功");
              this.$emit("on-upd");
            } else {
              this.$Message.success("新增成功");
              this.$emit("on-add");
            }
            this.close();
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          console.log(err);
          this.posting = false;
          this.$Message.error(err.errormsg);
        });
    },
    // 订单
    fastPickOrder() {
      this.formd.OrderId = this.$store.state.order.Id;
      this.$set(this.formd, "OrderCode", this.$store.state.order.Code);
      this.reqItems();
    },
    toPickOrder() {
      if (this.formd.OrderId) {
        this.$refs.PickOrder.open([
          { Id: this.formd.OrderId, Code: this.formd.OrderCode },
        ]);
      } else {
        this.$refs.PickOrder.open();
      }
    },
    onPickOrder(lst) {
      if (lst[0]) {
        this.formd.OrderId = lst[0].Id;
        this.$set(this.formd, "OrderCode", lst[0].Code);
        this.reqItems();
      }
    },
    unPickOrder() {
      this.formd.OrderId = "";
      this.$set(this.formd, "OrderCode", "");
      this.orderItems = [];
      this.formd.ItemId = 0;
    },
    // 编制人
    toPickEditor() {
      if (this.formd.EditorId) {
        this.$refs.PickUser.open([
          { Id: this.formd.EditorId, UserName: this.formd.EditorName },
        ]);
      } else {
        this.$refs.PickUser.open();
      }
    },
    onPickEditor(lst) {
      if (lst[0]) {
        this.formd.EditorId = lst[0].Id;
        this.$set(this.formd, "EditorName", lst[0].UserName);
      }
    },
    unPickEditor() {
      this.formd.EditorId = "";
      this.$set(this.formd, "EditorName", "");
    },
  },
};
</script>
<style lang="less">
.testing-syllabus-edit {
  .ivu-form-item {
    margin-right: 0;
    width: 50%;
  }
  .ivu-form-item.long {
    width: 100%;
  }
}
</style>