<template>
  <div>
    <Drawer
      class="bk-sub-drawer testing-syllabus-attach"
      v-model="flag"
      title="附件"
      :mask-closable="false"
      :transfer="false"
      width="400"
      @on-close="close"
    >
      <div class="bk-drawer-body">
        <Button
          icon="md-refresh"
          :disabled="loading"
          size="small"
          @click="req"
          style="margin-right: 10px"
        ></Button>
        <Button
          type="primary"
          icon="md-cloud-upload"
          size="small"
          @click="toAddAttach"
          v-if="$able.map.BtmsSyllabusAdd || $able.map.BtmsSyllabusUpd"
          >上传附件</Button
        >
        <div
          class="bk-loading text-primary"
          style="padding: 20px 0"
          v-if="loading"
        >
          <Icon type="ios-loading"></Icon> <span>Loading</span>
        </div>
        <div
          class="text-sub"
          style="padding: 20px 0"
          v-if="
            !loading && lst1.length == 0 && lst2.length == 0 && lst3.length == 0
          "
        >
          暂无数据
        </div>
        <div class="sub-titles" v-if="lst1.length > 0">大纲文件：</div>
        <div class="attach-item" v-for="(item, i) in lst1" :key="`attach-${i}`">
          <a class="text-primary" target="_blank" :href="item.Url">{{item.Name}}</a>
          <Icon v-if="$able.map.BtmsSyllabusDelTsAtta" @click="delAttach(item)" class="pointer" color="#ed4014" size="18" type="md-close" />
          <div class="text-sub" v-if="item.Tips">{{ item.Tips }}</div>
          <div class="text-sub text-xs">
            {{ item.CreateUserName }} / {{ item.CreateTime }}
          </div>
        </div>
        <div class="sub-titles" v-if="lst2.length > 0">内部文件：</div>
        <div
          class="attach-item"
          v-for="(item, i) in lst2"
          :key="`attach-inn-${i}`"
        >
          <a class="text-primary" target="_blank" :href="item.Url">{{item.Name}}</a>
          <Icon @click="delAttach(item)" class="pointer" color="#ed4014" size="18" type="md-close" />
          <div class="text-sub" v-if="item.Tips">{{ item.Tips }}</div>
          <div class="text-sub text-xs">
            {{ item.CreateUserName }} / {{ item.CreateTime }}
          </div>
        </div>
        <div class="sub-titles" v-if="lst3.length > 0">
          客户签章的大纲文件：
        </div>
        <div
          class="attach-item"
          v-for="(item, i) in lst3"
          :key="`attach-sign-${i}`"
        >
          <a class="text-primary" target="_blank" :href="item.Url">{{item.Name}}</a>
          <Icon @click="delAttach(item)" class="pointer" color="#ed4014" size="18" type="md-close" />
          <div class="text-sub" v-if="item.Tips">{{ item.Tips }}</div>
          <div class="text-sub text-xs">
            {{ item.CreateUserName }} / {{ item.CreateTime }}
          </div>
        </div>
      </div>
    </Drawer>
    <attach-add ref="attachAdd" @on-post="req" />
  </div>
</template>
<script>
import AttachAdd from "./attach-add";
export default {
  components: { AttachAdd },
  data() {
    return {
      id: 0,
      flag: false,
      loading: false,
      failed: false,
      lst1: [],
      lst2: [],
      lst3: [],
    };
  },
  methods: {
    open(id) {
      this.id = id;
      this.flag = true;
      this.req();
    },
    close() {
      this.flag = false;
      this.loading = false;
      this.failed = false;
      this.lst1 = [];
      this.lst2 = [];
      this.lst3 = [];
    },
    req() {
      this.lst1 = [];
      this.lst2 = [];
      this.lst3 = [];
      this.loading = true;
      this.$axios
        .get("/Api/DataAttachment/Query", {
          params: {
            Type: 6,
            TestingSyllabusId: this.id,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.result) {
            if (res.data) {
              let a = [],
                b = [],
                c = [];
              for (let x of res.data) {
                if (x.CategorySubCode == "TestingSyllabusFile") {
                  a.push(x);
                } else if (x.CategorySubCode == "TestingSyllabusFileInn") {
                  b.push(x);
                } else if (x.CategorySubCode == "TestingSyllabusFileSign") {
                  c.push(x);
                }
              }
              this.lst1 = a;
              this.lst2 = b;
              this.lst3 = c;
            }
          } else if (res.errormsg) {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.loading = false;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    toAddAttach() {
      this.$refs.attachAdd.open(this.id);
    },
    delAttach(row) {
      this.$Modal.confirm({
        title: "注意",
        content: "是否删除：" + this.$util.color(row.Name),
        onOk: () => {
          this.$axios
            .post("/Api/DataAttachment/Delete", { Ids: row.Id })
            .then((res) => {
              if (res.result) {
                this.$Message.success("删除成功");
                this.req();
              } else {
                if (res.errormsg) {
                  this.$Message.error(res.errormsg);
                }
              }
            })
            .catch((err) => {
              if (err.errormsg) {
                this.$Message.error(err.errormsg);
              }
            });
        },
      });
    },
  },
};
</script>
<style lang="less">
.testing-syllabus-attach {
  .sub-titles {
    font-weight: bold;
    position: relative;
    margin: 20px 0 10px;
  }
  .sub-titles::after {
    content: "";
    position: absolute;
    height: 2px;
    width: 2em;
    background-color: #2d8cf0;
    bottom: 0;
    left: 0;
  }
  .attach-item {
    background: #f8f8f9;
    border-radius: 4px;
    padding: 10px;
    margin: 10px 0;
    a {
      text-decoration: underline;
    }
  }
}
</style>

