<template>
  <div id="TestingSyllabusFlow">
    <Drawer :transfer="false" @on-close="close" class="bk-sub-drawer" v-model="flag" width="400">
      <div class="ivu-drawer-header-inner" slot="header">
        <!-- <span>测试大纲确认流程</span>
        <Divider type="vertical" /> -->
        <span class="text-sub text-xs">{{ syllabus.OrderCode }}</span>
        <Divider type="vertical" />
        <span class="text-sub text-xs">{{ syllabus.ItemName }}</span>
      </div>
      <div class="bk-drawer-body">
        <Spin class="bk-loading" fix v-if="loading">
          <div>
            <Icon type="ios-loading"></Icon>
            <div>Loading</div>
          </div>
        </Spin>
        <Spin class="bk-failed" fix v-if="failed">
          <div @click="reqFlow">
            <Icon type="md-refresh"></Icon>
            <p>Refresh</p>
          </div>
        </Spin>
        <div class="text-sub" v-if="flow === null">暂无数据</div>
        <div class="bk-drawer-body" v-if="flow">
          <div class="flow-item" style="margin-bottom: 30px">
            <div class="flex">
              <b>{{ flow.Name }}</b>
              <span class="text-primary" style="margin-left: 10px">
                {{
                flow.Tips
                }}
              </span>
            </div>
            <div class="flex">
              <div class="label">发起时间：</div>
              <div>
                <div>{{ flow.CreateTime }}</div>
              </div>
            </div>
            <div class="flex">
              <div class="label">委托方：</div>
              <div>
                <div class="text-primary">{{ flow.PartyaActionTips }}</div>
                <div class="text-sub text-xs">{{ flow.SignTimeA }}</div>
              </div>
            </div>
            <div class="flex" v-if="flow.PartybActionTips">
              <div class="label">我方：</div>
              <div>
                <div class="text-primary">{{ flow.PartybActionTips }}</div>
                <div class="text-sub text-xs">{{ flow.SignTimeB }}</div>
              </div>
            </div>
          </div>
          <attach-and-trace ref="attachAndTrace" />
        </div>
        <div class="bk-drawer-footer" v-if="canPush || canSign">
          <Button @click="toPushFlow" type="info" v-if="canPush">发起测试大纲确认</Button>
          <Button @click="confirmSign" type="info" v-if="canSign">确认签章文件</Button>
          <Button @click="toRePushSign" type="error" v-if="canSign">让客户重签</Button>
        </div>
      </div>
    </Drawer>
    <push-testing-syllabus-sign @on-post="onPushTestingSyllabusSign" ref="PushTestingSyllabusSign" />
    <re-push-testing-syllabus-sign @on-post="onRePushTestingSyllabusSign" ref="RePushTestingSyllabusSign" />
    <confirm-sign @on-post="onConfirmSign" ref="ConfirmSign" />
  </div>
</template>
<script>
import attachAndTrace from "@/components/attach-and-trace.vue";
import PushTestingSyllabusSign from "@/components/PushTestingSyllabusSign";
import RePushTestingSyllabusSign from "@/components/RePushTestingSyllabusSign";
import ConfirmSign from "@/components/ConfirmTestingSyllabusSign";
export default {
  components: { attachAndTrace, PushTestingSyllabusSign, RePushTestingSyllabusSign, ConfirmSign },
  data() {
    return {
      syllabus: {},
      flag: false,
      loading: false,
      failed: false,
      canPush: false,
      canSign: false,
      flow: "",
    };
  },
  methods: {
    open(d) {
      this.syllabus = d;
      this.flag = true;
      this.reqFlow();
    },
    close() {
      this.syllabus = {};
      this.flag = false;
      this.loading = false;
      this.canPush = false;
      this.canSign = false;
      if (this.flow) {
        this.$refs.attachAndTrace.clear();
      }
      this.flow = "";
    },
    reqFlow() {
      this.canPush = false;
      this.canSign = false;
      this.loading = true;
      this.$axios
        .get("/Api/BtmsFlow/GetLast", {
          params: {
            FlowCode: "TESTING-SYLLABUS-SIGN",
            TestingSyllabusId: this.syllabus.Id,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.result) {
            this.flow = res.data;
            if (this.flow) {
              this.$nextTick(() => {
                this.$refs.attachAndTrace.init(
                  res.data.OrderId,
                  res.data.Id,
                  {
                    delAttachFlag: this.$able.map.BtmsOrderDelFlowAtta
                  },
                  {
                    ItemId: this.syllabus.ItemId,
                    TestingSyllabusId: this.syllabus.Id,
                    FlowCode: "TESTING-SYLLABUS-SIGN"
                  }
                );
              });
              this.canSign =
                this.flow.PartybAction == 0 &&
                this.flow.PartyaAction == 1 &&
                this.$able.map.BtmsSyllabusSign;
            } else {
              this.canPush =
                this.syllabus.Status == 1 && this.$able.map.BtmsSyllabusSign;
            }
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$Message.error(err.errormsg);
        });
    },
    // ——————————
    toPushFlow() {
      this.$refs.PushTestingSyllabusSign.open(this.syllabus.Id);
    },
    onPushTestingSyllabusSign() {
      this.reqFlow();
      this.$emit("on-flow");
    },
    // ——————————
    toRePushSign() {
      this.$refs.RePushTestingSyllabusSign.open(this.syllabus.Id);
    },
    onRePushTestingSyllabusSign() {
      this.reqFlow();
      this.$emit("on-flow");
    },
    // ——————————
    confirmSign() {
      this.$refs.ConfirmSign.open(this.flow.TestingSyllabusId);
    },
    onConfirmSign() {
      this.reqFlow();
      this.$emit("on-flow");
    },
  },
};
</script>
<style lang="less" scoped>
#TestingSyllabusFlow {
  .ivu-drawer-header-inner {
    width: calc(~"100% - 20px");
  }
  .bk-drawer-body {
    padding-top: 20px;
  }
  .flow-item {
    .flex {
      margin-bottom: 10px;
    }
    .label {
      width: 5em;
      text-align: right;
    }
  }
  .sub-titles {
    font-weight: bold;
    position: relative;
  }
  .sub-titles::after {
    content: "";
    position: absolute;
    height: 2px;
    width: 2em;
    background-color: #2d8cf0;
    bottom: 0;
    left: 0;
  }
  .attach-item {
    margin: 10px 0;
    a {
      text-decoration: underline;
    }
  }
}
</style>
