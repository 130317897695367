<template>
  <div class="bk-page" id="BtmsTestingSyllabus">
    <div class="bk-page-scroll">
      <div class="bk-page-lst">
        <Form :label-width="80" @submit.native.prevent class="bk-page-search" inline>
          <!-- <FormItem label="委托单">
            <Input :value="searchData1.OrderCode" @on-clear="unPickOrder" clearable readonly>
              <Button @click="toPickOrder" icon="ios-more" slot="append"></Button>
            </Input>
          </FormItem> -->
          <FormItem :label-width="56" label="实验室">
            <Select clearable v-model="searchData1.LabId">
              <Option :key="item.Id" :value="item.Id" v-for="item in LabLst">{{ item.Name }}</Option>
            </Select>
          </FormItem>
          <FormItem :label-width="68" label="委托单号">
            <Input v-model="searchData1.OrderCode" />
          </FormItem>
          <FormItem label="测试项目">
            <Select clearable v-model="searchData1.ItemId">
              <Option
                :key="`item-${item.Id}`"
                :value="item.Id"
                v-for="item in orderItems"
              >{{ item.Name }}</Option>
            </Select>
          </FormItem>
          <!-- <FormItem label="大纲名称">
            <Input v-model="searchData1.Name" />
          </FormItem>-->
          <FormItem label="大纲编码">
            <Input v-model="searchData1.Code" />
          </FormItem>
          <FormItem label="状态">
            <Select v-model="searchData1.Status">
              <Option
                :key="item.key"
                :value="item.key"
                v-for="item in $designConst.testingSyllabus.StatusLst"
              >{{ item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem class="btns">
            <Button @click="onSearch" type="primary">搜索</Button>
            <Button @click="reSearchData" type="default">重置</Button>
            <Button @click="toAdd" ghost type="primary" v-if="$able.map.BtmsSyllabusAdd">
              <Icon type="md-add" />新增
            </Button>
          </FormItem>
          <Button style="margin: 0 10px 10px 0" :to="syllabusTemplate" target="_blank">
            <Icon type="md-download" />下载测试大纲文档模板
          </Button>
        </Form>
        <Table :columns="tableCols" :data="tableData" :loading="tableLoading"></Table>
        <Page
          :current="pageIndex"
          :page-size="pageSize"
          :total="recordCount"
          @on-change="(index) => onChangePage(index, 0)"
          @on-page-size-change="(size) => onChangePage(1, size)"
          show-elevator
          show-sizer
          show-total
          transfer
        />
      </div>
    </div>
    <pick-order @on-ok="onPickOrder" ref="PickOrder" />
    <edit @on-add="onChangePage(1)" @on-upd="reqTable" ref="edit" />
    <flow @on-flow="reqTable" ref="flow" />
    <attach-lst ref="attachLst" />
  </div>
</template>
<script>
import SelectLabId from "@/components/select-labid.js";
import PickOrder from "../../components/PickOrder.vue";
import edit from "./edit.vue";
import flow from "./flow";
import AttachLst from "./attach-lst.vue";
export default {
  mixins: [SelectLabId],
  components: { PickOrder, edit, flow, AttachLst },
  computed: {
    syllabusTemplate: () => {
      return (
        (/^https/.test(window.location.href) ? "https://" : "http://") +
        window.location.host +
        "/file/priv/a801314265024f26a12ba0621e8ac520/teststmpl/docx"
      );
    },
  },
  data() {
    return {
      orderItems: [],
      searchData1: {},
      searchData2: {},
      // ——————————
      tableLoading: false,
      tableCols: [
        { title: "委托单", key: "OrderCode" },
        { title: "测试项目", key: "ItemName" },
        // { title: "大纲名称", key: "Name" },
        { title: "大纲编码", key: "Code" },
        {
          title: "状态",
          key: "Status",
          render: (h, params) => {
            return h("Badge", {
              props: {
                status:
                  this.$designConst.testingSyllabus.StatusBadge[
                    params.row.Status
                  ],
                text: this.$designConst.testingSyllabus.Status[
                  params.row.Status
                ],
              },
            });
          },
        },
        { title: "编制人", key: "EditorName" },
        {
          title: "操作",
          width: 280,
          align: "right",
          render: (h, params) => {
            let a = [];
            a.push(
              h(
                "span",
                {
                  style: { cursor: "pointer", color: "#2d8cf0" },
                  on: {
                    click: () => {
                      this.$refs.flow.open({
                        Id: params.row.Id,
                        Status: params.row.Status,
                        OrderCode: params.row.OrderCode,
                        ItemName: params.row.ItemName,
                        ItemId: params.row.ItemId,
                      });
                    },
                  },
                },
                "测试大纲确认流程"
              )
            );
            a.push(h("Divider", { props: { type: "vertical" } }));
            a.push(
              h(
                "span",
                {
                  style: { cursor: "pointer", color: "#2d8cf0" },
                  on: {
                    click: () => {
                      this.$refs.attachLst.open(params.row.Id);
                    },
                  },
                },
                "大纲附件"
              )
            );
            a.push(h("Divider", { props: { type: "vertical" } }));
            a.push(
              h("Icon", {
                props: {
                  type: this.$able.map.BtmsSyllabusUpd
                    ? "md-create"
                    : "md-open",
                  size: 18,
                },
                attrs: {
                  title: this.$able.map.BtmsSyllabusUpd ? "编辑" : "详情",
                },
                style: { cursor: "pointer", color: "#2d8cf0" },
                on: {
                  click: () => {
                    this.$refs.edit.open(params.row.Id);
                  },
                },
              })
            );
            a.push(h("Divider", { props: { type: "vertical" } }));
            if (this.$able.map.BtmsSyllabusDel) {
              a.push(
                h("Icon", {
                  props: { type: "md-close", size: 18 },
                  attrs: { title: "删除" },
                  style: { cursor: "pointer", color: "#ed4014" },
                  on: {
                    click: () => {
                      this.toDel(params.row);
                    },
                  },
                })
              );
            }
            return a;
          },
        },
      ],
      tableData: [],
      // ——————————
      recordCount: 0,
      pageIndex: 1,
      pageSize: 10,
    };
  },
  activated: function () {
    console.info("激活：", this.$tab.conf[this.$route.name].label);
    this.$root.$off("on-refresh");
    this.$root.$on("on-refresh", () => {
      this.init();
    });
    if (this.$route.params.re == 1) {
      this.init();
    }
  },
  methods: {
    init() {
      if (this.$refs.edit.flag) {
        this.$refs.edit.close();
      }
      if (this.$refs.attachLst.flag) {
        this.$refs.attachLst.close();
      }
      if (this.$refs.flow.flag) {
        this.$refs.flow.close();
      }
      if (window.toTabData) {
        if (window.toTabData.FlowCode == "TESTING-SYLLABUS-SIGN") {
          this.searchData1 = {
            OrderId: window.toTabData.OrderId,
            OrderCode: window.toTabData.OrderCode,
            ItemId: window.toTabData.ItemId,
          };
          this.reqItems();
          this.pageIndex = 1;
          this.onSearch();
          this.$refs.flow.open({
            OrderCode: window.toTabData.OrderCode,
            ItemName: window.toTabData.ItemName,
            ItemId: window.toTabData.ItemId,
            Id: window.toTabData.TestingSyllabusId,
          });
          window.toTabData = null;
        } else {
          window.toTabData = null;
          this.initSearch();
        }
      } else if (this.$store.state.order.Id) {
        this.searchData1 = {
          OrderId: this.$store.state.order.Id,
          OrderCode: this.$store.state.order.Code,
        };
        this.reqItems();
        this.pageIndex = 1;
        this.onSearch();
      } else {
        this.initSearch();
      }
      this.reqLab();
    },
    // 初始化搜索
    initSearch() {
      this.reSearchData();
      this.pageIndex = 1;
      this.onSearch();
    },
    reSearchData() {
      this.searchData1 = {};
    },
    onSearch() {
      let data = this.$util.copy(this.searchData1);
      this.searchData2 = data;
      this.pageIndex = 1;
      this.reqTable();
    },
    reqTable() {
      if (this.$able.map.BtmsSyllabusQry == undefined) {
        this.$Message.error("无权限查询");
        return;
      }
      let params = Object.assign(
        {
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
        },
        this.searchData2
      );
      this.tableLoading = true;
      this.$axios
        .get("/Api/BtmsTestingSyllabus/QueryPg", { params: params })
        .then((res) => {
          this.tableLoading = false;
          if (res.result) {
            this.recordCount = res.data.RecordCount;
            this.tableData = res.data.DataSet || [];
          } else if (res.errormsg) {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.tableLoading = false;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    onChangePage(index, size) {
      if (index > 0) {
        this.pageIndex = index;
      }
      if (size > 0) {
        this.pageSize = size;
      }
      this.reqTable();
    },
    // 委托单
    toPickOrder() {
      if (this.searchData1.OrderId) {
        this.$refs.PickOrder.open([
          { Id: this.searchData1.OrderId, Code: this.searchData1.OrderCode },
        ]);
      } else {
        this.$refs.PickOrder.open();
      }
    },
    onPickOrder(lst) {
      if (lst[0]) {
        this.searchData1.OrderId = lst[0].Id;
        this.$set(this.searchData1, "OrderCode", lst[0].Code);
        this.reqItems();
      } else {
        this.unPickOrder();
      }
    },
    unPickOrder() {
      this.searchData1.OrderId = "";
      this.$set(this.searchData1, "OrderCode", "");
      this.searchData1.ItemId = 0;
      this.orderItems = [];
    },
    // 测试项目
    reqItems() {
      this.$axios
        .get("/Api/BtmsOrderItems/QueryPg", {
          params: {
            PageIndex: 1,
            PageSize: 99,
            OrderId: this.searchData1.OrderId,
          },
        })
        .then((res) => {
          if (res.result) {
            this.orderItems = res.data.DataSet;
          }
        });
    },
    // ==========
    toAdd() {
      this.$refs.edit.open();
    },
    toDel(row) {
      this.$Modal.confirm({
        title: "注意",
        content: "是否删除：" + this.$util.color(row.Name),
        onOk: () => {
          this.doDel(row.Id);
        },
      });
    },
    doDel(id) {
      this.$axios
        .post("/Api/BtmsTestingSyllabus/Delete", { Id: id })
        .then((res) => {
          if (res.result) {
            this.$Message.success("删除成功");
            this.reqTable();
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
  },
};
</script>
<style lang="less">
#BtmsTestingSyllabus {
  .bk-page-search > .ivu-btn {
    margin-right: 10px;
  }
}
</style>