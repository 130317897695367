<template>
  <div>
    <Modal class="testingSyllabus-attach" v-model="flag" title="附件/大纲" :mask-closable="false" :transfer="false" width="400" @on-cancel="close">
      <Form @submit.native.prevent :model="formd" :rules="rules" ref="form">
        <FormItem label="附件类型：" prop="Type" required>
          <RadioGroup v-model="formd.Type">
            <Radio :label="1">大纲文件</Radio>
            <Radio :label="2">内部文件</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem>
          <Upload multiple type="drag" action="/File/PostTestingSyllabusFile" name="file" :data="formd" :show-upload-list="false" :before-upload="handleUpload">
            <div style="padding:20px;">
              <Icon type="md-cloud-upload" size="52" style="color: #3399ff;"></Icon>
              <p class="text-sub">点击 或 拖拽 上传</p>
              <p v-if="file" class="text-primary">{{file.name}}</p>
            </div>
          </Upload>
        </FormItem>
      </Form>

      <div slot="footer">
        <Button type="primary" :loading="posting" @click="ok">确定</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      flag: false,
      formd: {}, // type:1:大纲文件;2:内部文件;
      rules: {
        Type: [{ required: true, type: "number", message: "请选择类型" }]
      },
      file: "",
      posting: false
    };
  },
  methods: {
    open(id) {
      this.formd.Id = id;
      this.flag = true;
    },
    close() {
      this.flag = false;
      this.posting = false;
      this.formd = {};
      this.$refs.form.resetFields();
      this.file = ""
    },
    ok() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (!this.file) {
            this.$Message.warning("请选择文件")
          } else {
            this.postData();
          }
        }
      });
    },
    handleUpload(file) {
      this.file = file;
      return false;
    },
    postData() {
      let formdata = new FormData();
      formdata.append("Id", this.formd.Id);
      formdata.append("Type", this.formd.Type);
      formdata.append("File", this.file);
      let config = { headers: { "Content-Type": "multipart/form-data" } };
      this.posting = true;
      this.$axios
        .post("/File/PostTestingSyllabusFile", formdata, config)
        .then(res => {
          this.posting = false;
          if (res.result) {
            this.$Message.success("上传成功")
            this.$emit("on-post")
            this.close()
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch(err => {
          this.posting = false;
          this.$Message.error(err.errormsg);
        });
    }
  }
};
</script>
<style lang="less">
.testingSyllabus-attach {
  .dis-upload {
    border: 1px dashed rgb(220, 222, 226);
    border-radius: 4px;
    text-align: center;
    padding: 20px;
    cursor: not-allowed;
    margin-bottom: 10px;
  }
}
</style>

